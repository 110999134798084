import axios from 'axios'
import indexBD from '../serviceBDLocal/indexBD'

const login = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post('https://registroapi.aplicacionesincontacto.com/user/login',
                {
                    username: data.username,
                    password: data.password,
                    browser: getBrowser(),
                    os: getSystemOperativo(),
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    })
}

const getUserEvents = (username) => {
    return new Promise((resolve, reject) => {
        axios
            .get('https://registroapi.aplicacionesincontacto.com/user/' + username + "/" + diff_to_GMT())
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    })
}

const closeAllSessionNotEvent = (userId) => {
    return new Promise((resolve, reject) => {
        axios
            .get('https://registroapi.aplicacionesincontacto.com/sessions/close/all/user/' + userId)
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    })
}


const getEvents = () => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        let token = users[0].token;
        axios
            .get('https://registroapi.aplicacionesincontacto.com/exhibitor/events/users/' + diff_to_GMT(),
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    })
}

const syncPerson = (eventId, dataSync) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        let token = users[0].token;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/exhibitor/user/event/' + eventId + '/asyn/leads',
                dataSync,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    });
}



const reportError = (eventId, dataSync, message) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        let token = users[0].token;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/exhibitor/user/event/' + eventId + '/reporterror/leads',
                {
                    dataSync,
                    message
                },
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))

    });
}

const diff_to_GMT = () => {
    let now = new Date();
    return -(now.getTimezoneOffset()) / 60;
}

const getBrowser = () => {
    var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}
const getSystemOperativo = () => {
    var OSName = "Desconocido";
    if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
    if (navigator.appVersion.indexOf("Android") != -1) OSName = "Android";

    return OSName;
}

const getAvaliablesZones = () => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .get('https://registroapi.aplicacionesincontacto.com/zone/zones/event/' + event._id,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })

}

const getScreen = () => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .get('https://registroapi.aplicacionesincontacto.com/event/' + event._id + "/screen",
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })

}

const allFieldsPublic = () => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .get('https://registroapi.aplicacionesincontacto.com/event/' + event._id + "/all/field/public",
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    });
}

const findIdentificationOrIdOnly = (assistantId, isScreen = false) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        let body = {};
        body.assistantId = assistantId;
        body.isScreen = isScreen;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/assistant/event/' + event._id + "/findidordocument/only",
                body,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    });
}

const accessControl = (valueAssitant, zoneId) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/assist/save',
                {
                    eventId: event._id,
                    valueAssitant,
                    zone: zoneId
                },
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })

}

const validationfieldrequired = (data) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/assistant/event/' + event._id + '/validationfieldrequired',
                data,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })

}

const getInformationAssistant = (assistantId) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        let token = users[0].token;
        axios
            .get('https://registroapi.aplicacionesincontacto.com/assistant/' + assistantId + '/information',
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })
}

const printassistant = (data) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;

        let body = {};
        body.assistants = data;
        body.print = 'No selected';
        let configurationServer = localStorage.getItem("configurationServer");
        if (configurationServer) {
            let config = JSON.parse(configurationServer);
            if (config && config.print) {
                body.print = config.print;
            }
            body.urlPrint = config.ip + `/print`;
        }
        axios
            .put('https://registroapi.aplicacionesincontacto.com/assistant/event/' + event._id + '/printassistant',
                body,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })
}

const getPrints = (urlPrint) => {
    return new Promise(async (resolve, reject) => {
        axios
            .get(urlPrint + '/print/print/all')
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })

}


const markCheckedAssistant = (data) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .put('https://registroapi.aplicacionesincontacto.com/assistant/event/' + event._id + '/markcheckedsssistant',
                data,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })
}

const allAssistantPagination = (value, page, size, conditional, groupInscription, suggested) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/assistant/all/' + event._id + '/pagination',
                {
                    value,
                    page,
                    size,
                    conditional,
                    groupInscription,
                    suggested
                },
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })
}

const findAssistantByAccessControl = (valueAssitant, zone) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/assist/findassistant',
                {
                    eventId: event._id,
                    valueAssitant,
                    zone
                },
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })
}

const validationfieldaccesscontrol = (data, zoneId) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/assistant/event/' + event._id + '/validationfieldaccesscontrol',
                {
                    ids: data, 
                    zoneId: zoneId
                },
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })
}

const saveAssistantRequired = (assistantId, body) => {
    return new Promise(async (resolve, reject) => {
        const db = await indexBD.getDb()
        const users = await indexBD.getUsers(db)
        const eventSelected = await indexBD.getEventSelecteds(db)
        let token = users[0].token;
        let event = eventSelected[0].event;
        axios
            .post('https://registroapi.aplicacionesincontacto.com/assistant/'+assistantId+'/event/' + event._id + '/save/required',
                body
               ,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })
}

const printService = (body) => {
    return new Promise(async (resolve, reject) => {
        let url = body.urlPrint;
        axios
            .post(url,
                body)
            .then(response => {
                resolve(response)
            }).catch(error => reject(error))
    })

}

export default {
    login,
    getEvents,
    syncPerson,
    reportError,
    getSystemOperativo,
    getBrowser,
    getUserEvents,
    closeAllSessionNotEvent,
    getAvaliablesZones,
    accessControl,
    getScreen,
    allFieldsPublic,
    findIdentificationOrIdOnly,
    getPrints,
    validationfieldrequired,
    getInformationAssistant,
    printassistant,
    markCheckedAssistant,
    printService,
    allAssistantPagination,
    findAssistantByAccessControl,
    validationfieldaccesscontrol,
    saveAssistantRequired
}